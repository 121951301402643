import React, { useEffect } from "react";
import { Typography } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import { setCountDown } from "../../reducers/eolSlice";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;

const Timer = (props) => {
  const { loading, className } = props;
  const dispatch = useDispatch();
  const countDown = useSelector((state) => state.eol.countDown);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        dispatch(setCountDown(countDown + 1));
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [countDown, dispatch, loading]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <Text className={className}>
      {formatTime(countDown)}
      <FieldTimeOutlined style={{ marginLeft: "1rem" }} />
    </Text>
  );
};

export default Timer;
