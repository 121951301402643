import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

const Panels = (props) => {
  const userDetails = useSelector((state) => state.auth.userDetails);

  const { fleet, imei } = props;
  const src1 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/df7bae09-a1aa-415b-b17f-033da73283ad/accelerometerdashboard?orgId=${userDetails?.organisations[0]?.id}&var-fleet=${fleet}&var-imei=${imei}&from=1687260491000&to=1688137417000&panelId=1`;
  const src2 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/df7bae09-a1aa-415b-b17f-033da73283ad/accelerometerdashboard?orgId=${userDetails?.organisations[0]?.id}&var-fleet=${fleet}&var-imei=${imei}&from=1687260491000&to=1688137417000&panelId=2`;
  const src3 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/df7bae09-a1aa-415b-b17f-033da73283ad/accelerometerdashboard?orgId=${userDetails?.organisations[0]?.id}&var-fleet=${fleet}&var-imei=${imei}&from=1687260491000&to=1688137417000&panelId=3`;
  const src4 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/df7bae09-a1aa-415b-b17f-033da73283ad/accelerometerdashboard?orgId=${userDetails?.organisations[0]?.id}&var-fleet=${fleet}&var-imei=${imei}&from=1687260491000&to=1688137417000&panelId=4`;

  return (
    <Row className="mt2">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            {fleet && imei && (
              <iframe
                title="Accelerometer"
                src={src1}
                width="100%"
                height="400"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
          <Col span={12}>
            {fleet && imei && (
              <iframe
                title="ac_x"
                src={src2}
                width="100%"
                height="400"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
        </Row>

        <Row gutter={16} className="mt1">
          <Col span={12}>
            {fleet && imei && (
              <iframe
                title="ac_y"
                src={src3}
                width="100%"
                height="400"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
          <Col span={12}>
            {fleet && imei && (
              <iframe
                title="ac_z"
                src={src4}
                width="100%"
                height="400"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Panels;
