import React, { useEffect, useMemo, useState } from "react";
import { Layout, Menu, Dropdown, Space, theme } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { setLoggedOut } from "../../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LOGO from "../../assets/images/logo.svg";
import ACTIVE_EOL_TEST from "../../assets/images/icons/active_eol_test.svg";
import EOL_TEST from "../../assets/images/icons/eol_test.svg";
import DRIVER_BEHAVIOUR from "../../assets/images/icons/driver_behaviour.svg";
import ACTIVE_DRIVER_BEHAVIOUR from "../../assets/images/icons/active_driver_behaviour.svg";
import MOTOR_STATUS from "../../assets/images/icons/motor_status.svg";
import ACTIVE_MOTOR_STATUS from "../../assets/images/icons/active_motor_status.svg";
import EOL_SUMMARY from "../../assets/images/icons/eol_summary.svg";
import ACTIVE_EOL_SUMMARY from "../../assets/images/icons/active_eol_summary.svg";

import "./style.css";

const { Header, Content, Sider } = Layout;

const AuthLayout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const role = userDetails.roles[0];
  const urlElements = location.pathname.split("/");
  let lastValue = urlElements[urlElements.length - 1];
  const [currentSidebar, setCurrentSidebar] = useState(lastValue);

  const list = useMemo(
    () => [
      {
        key: "motorStatus",
        label: (
          <a href="/motorStatus" className="flex">
            <img
              src={
                lastValue === "motorStatus" ? ACTIVE_MOTOR_STATUS : MOTOR_STATUS
              }
              alt="Logo"
              className="mr075"
            />
            Motor Status
          </a>
        ),
      },
      {
        key: "driverBehaviour",
        label: (
          <a href="/driverBehaviour" className="flex">
            <img
              src={
                lastValue === "driverBehaviour"
                  ? ACTIVE_DRIVER_BEHAVIOUR
                  : DRIVER_BEHAVIOUR
              }
              alt="Logo"
              className="mr075"
            />
            Driver Behaviour
          </a>
        ),
      },
      {
        key: (lastValue === "eolTestHeartBeat" || lastValue === "eolTestDyno") ? lastValue : 'eolTestHeartBeat',
        label: (
          <a href="/eolTestHeartBeat" className="flex">
            <img
              src={
                lastValue === "eolTestHeartBeat" || lastValue === "eolTestDyno"
                  ? ACTIVE_EOL_TEST
                  : EOL_TEST
              }
              alt="Logo"
              className="mr075"
            />{" "}
            EOL Test
          </a>
        ),
      },
      {
        key: "eolSummary",
        label: (
          <a href="/eolSummary" className="flex">
            <img
              src={
                lastValue === "eolSummary" ? ACTIVE_EOL_SUMMARY : EOL_SUMMARY
              }
              alt="Logo"
              className="mr075"
            />
            EOL Summary
          </a>
        ),
      },
    ],
    [lastValue]
  );

  const [sidebarItems, setSidebarItems] = useState(list);

  useEffect(() => {
    if (role === "Test Engineer") {
      setSidebarItems([
        {
          key: lastValue,
          label: (
            <a href="/eolTestHeartBeat" className="flex">
              <img
                src={
                  lastValue === "eolTestHeartBeat" ||
                  lastValue === "eolTestDyno"
                    ? ACTIVE_EOL_TEST
                    : EOL_TEST
                }
                alt="Logo"
                className="mr075"
              />{" "}
              EOL Test
            </a>
          ),
        },
        {
          label: (
            <a href="/eolSummary" className="flex">
              <img
                src={
                  lastValue === "eolSummary" ? ACTIVE_EOL_SUMMARY : EOL_SUMMARY
                }
                alt="Logo"
                className="mr075"
              />
              EOL Summary
            </a>
          ),
          key: "eolSummary",
        },
      ]);
    } else if (role === "Fleet Manager") {
      setSidebarItems([
        {
          key: "motorStatus",
          label: (
            <a href="/motorStatus" className="flex">
              <img
                src={
                  lastValue === "motorStatus"
                    ? ACTIVE_MOTOR_STATUS
                    : MOTOR_STATUS
                }
                alt="Logo"
                className="mr075"
              />
              Motor Status
            </a>
          ),
        },
        {
          key: "driverBehaviour",
          label: (
            <a href="/driverBehaviour" className="flex">
              <img
                src={
                  lastValue === "driverBehaviour"
                    ? ACTIVE_DRIVER_BEHAVIOUR
                    : DRIVER_BEHAVIOUR
                }
                alt="Logo"
                className="mr075"
              />
              Driver Behaviour
            </a>
          ),
        },
      ]);
    } else {
      setSidebarItems(list);
    }
    setCurrentSidebar(lastValue);
  }, [currentSidebar, lastValue, list, role]);

  const getFirstLetters = (inputString) => {
    const words = inputString.split(" ");
    const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
    return firstLetters.join("");
  };

  const onClickSidebar = (e) => {
    setCurrentSidebar(e.key);
    navigate(`/${e.key}`);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setLoggedOut());
    navigate("/login");
  };

  const items = [
    {
      label: <a href="/profile">Profile</a>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <Link onClick={handleLogout}>Logout</Link>,
      key: "2",
    },
  ];

  const onClickDashboard = (e) => {
    navigate(`/${e.key}`);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1D2939",
          boxShadow: "0px 1px 4px -2px #00001A",
          marginBottom: "0.1rem",
          color: "#fff",
        }}
      >
        <img
          src={LOGO}
          alt="Logo"
          style={{ marginLeft: "1rem", width: "2.5rem", height: "2.5rem" }}
        />
        <div className="logo">NicheSolv EV</div>
        <Menu
          onClick={onClickDashboard}
          mode="horizontal"
          style={{ marginLeft: "auto" }}
          items={[]}
        />

        <Dropdown
          menu={{
            items,
          }}
        >
          <Space>
            <div className="dp">{getFirstLetters(role)}</div>
            {`${userDetails.organisations[0].name} ${role}`}
            <DownOutlined />
          </Space>
        </Dropdown>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            onClick={onClickSidebar}
            selectedKeys={[currentSidebar]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={sidebarItems}
          />
        </Sider>
        <Layout
          style={{
            padding: "0 15px 4px 20px",
          }}
        >
          <Content
            style={{
              padding: "10px 0",
              margin: 0,
              minHeight: 580,
              background: "#f5f5f5",
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
