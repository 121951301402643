export const userApiBaseUrl = process.env.REACT_APP_USER_API_BASE_URL;
export const vehicleApiBaseUrl = process.env.REACT_APP_VEHICLE_API_BASE_URL;

export const userJwtLoginUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_URL;

export const userJwtLoginSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_SCHEMA_URL;

export const imeiListApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_IMEI_LIST_URL;

export const vehicleDetailsApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_DETAILS_URL;

export const vehicleTestStartApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_START_URL;

export const vehicleTestStopApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_STOP_URL;

export const vehicleTestResultApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RESULT_URL;

export const vehicleAddCommentsApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_ADD_COMMENTS_URL;

export const vehicleTestListUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_LIST_URL;
