import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { getApis, postApis } from "../../../../common/apis";
import { useDispatch, useSelector } from "react-redux";
import VehicleDetails from "../../../VehicleDetails";
import {
  vehicleTestResultApiUrl,
  vehicleTestStartApiUrl,
  vehicleTestStopApiUrl,
} from "../../../../common/endpoints";
import Comment from "../../Comment";
import "./style.css";
import {
  setDynoResult,
  setDynoStart,
  setDynoStop,
  setTestRunning,
} from "../../../../../reducers/eolSlice";
import History from "./History";
import Result from "./Result";

const Panels = () => {
  const dispatch = useDispatch();
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const dynoStart = eol?.dynoStart;
  const dynoResult = eol?.dynoResult;
  const dynoStop = eol?.dynoStop;
  const testRunning = eol?.testRunning;
  const selectedTab = eol?.selectedTab;

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const dynoResultApi = useCallback(async () => {
    const testId = dynoStart?.testId
      ? dynoStart?.testId
      : vehicleDetails?.previousTests?.dyno;
    if (testId) {
      const response = await getApis(vehicleTestResultApiUrl, {
        testId: testId,
        imei: vehicleDetails?.imeiNumber,
      });
      dispatch(setDynoResult(response?.data));
      dispatch(setTestRunning(response?.data?.status === "RUNNING"));
    }
  }, [
    dispatch,
    dynoStart?.testId,
    vehicleDetails?.imeiNumber,
    vehicleDetails?.previousTests?.dyno,
  ]);

  const startTest = async () => {
    setBtnDisable(true);
    const currentTime = Math.floor(Date.now());
    const response = await postApis(vehicleTestStartApiUrl, {
      imei: vehicleDetails?.imeiNumber,
      startTime: currentTime,
      type: "DYNO",
    });
    dispatch(setDynoStart(response?.data));
    dispatch(setTestRunning(true));
    dispatch(setDynoStop({}));
    setBtnDisable(false);
  };

  useEffect(() => {
    let intervalId;

    if (testRunning) {
      intervalId = setInterval(() => {
        dynoResultApi();
      }, 5000);
    } else if (vehicleDetails?.previousTests?.dyno && selectedTab === "dyno") {
      dynoResultApi();
    }

    return () => clearInterval(intervalId);
  }, [
    testRunning,
    dynoResultApi,
    vehicleDetails?.previousTests?.dyno,
    selectedTab,
  ]);

  const stopTest = async () => {
    setBtnDisable(true);
    const currentTime = Math.floor(Date.now());
    const response = await postApis(vehicleTestStopApiUrl, {
      imei: vehicleDetails?.imeiNumber,
      stopTime: currentTime,
      testId: dynoResult?.testId || vehicleDetails?.previousTests?.dyno,
      type: "DYNO",
    });
    dispatch(setDynoStop(response?.data));
    dispatch(setTestRunning(false));
    setIsCommentModalOpen(true);
    setBtnDisable(false);
  };

  const renderLayout = () => {
    if (dynoStop?.status === "COMPLETED" || testRunning === true) {
      return <Result />;
    } else {
      return <History />;
    }
  };

  return (
    <Row className="mt1">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <VehicleDetails
              startTest={startTest}
              stopTest={stopTest}
              btnDisable={btnDisable}
            />
          </Col>
        </Row>
        <Comment
          formName="dyno"
          isModalOpen={isCommentModalOpen}
          testId={vehicleDetails?.previousTests?.dyno || dynoStart?.testId}
        />
        {renderLayout()}
      </Col>
    </Row>
  );
};

export default Panels;
