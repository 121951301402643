import { Card, Col, Row } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const Result = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const connectivityStop = eol?.connectivityStop;
  const testRunning = eol?.testRunning || eol?.playing;

  const volt_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2df/eol-connectivity?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${connectivityStop.startTime}&to=${connectivityStop.stopTime}&refresh=5s&panelId=7`;
  const current_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2df/eol-connectivity?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${connectivityStop.startTime}&to=${connectivityStop.stopTime}&refresh=5s&panelId=6`;

  const connectionIcon = (status) => {
    if (status === true) {
      return (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "18px", margin: "0rem 0.5rem" }}
        />
      );
    } else if (status === false) {
      return (
        <CloseCircleTwoTone
          twoToneColor="#f81d22"
          style={{ fontSize: "18px", margin: "0rem 0.5rem" }}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Row gutter={16} className="mt1">
        <Col span={6}>
          <Card title="TCU Data" bordered={false}>
            <Row gutter={10}>
              <Col className="card-sub-text">Connection Status</Col>
              <Col>
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  connectionIcon(connectivityStop?.data?.connection?.tcu)
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title="Motor Data"
            bordered={false}
            style={{ alignItems: "center" }}
          >
            <Row gutter={10}>
              <Col className="card-sub-text">Connection Status</Col>
              <Col>
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  connectionIcon(connectivityStop?.data?.connection?.motor)
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="BMS Data" bordered={false}>
            <Row gutter={10}>
              <Col className="card-sub-text">Connection Status</Col>
              <Col>
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  connectionIcon(connectivityStop?.data?.connection?.battery)
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Location Data" bordered={false}>
            <Row gutter={10}>
              <Col className="card-sub-text">Connection Status</Col>
              <Col>
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  connectionIcon(connectivityStop?.data?.connection?.gps)
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mt2">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col className="mode-header">Current Check:</Col>
                    <Col>
                      {testRunning ? (
                        <Spin size="small" />
                      ) : (
                        connectionIcon(
                          connectivityStop?.data?.threshold?.current_checked
                        )
                      )}
                    </Col>
                    {/* (64 to 84 volts from birth) */}
                  </Row>
                </Col>
              </Row>
              {vehicleDetails?.imeiNumber && (
                <iframe
                  title="Current"
                  src={current_src}
                  width="100%"
                  height="282"
                  frameBorder="0"
                ></iframe>
              )}
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col className="mode-header">Voltage Check:</Col>
                    <Col>
                      {testRunning ? (
                        <Spin size="small" />
                      ) : (
                        connectionIcon(
                          connectivityStop?.data?.threshold?.voltage_checked
                        )
                      )}
                    </Col>
                    {/* {"(current < 100 amps from birth)"} */}
                  </Row>
                </Col>
              </Row>
              {vehicleDetails?.imeiNumber && (
                <iframe
                  title="Voltage"
                  src={volt_src}
                  width="100%"
                  height="282"
                  frameBorder="0"
                ></iframe>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16} className="mt1">
        <Col span={12}>
          <Card bordered={false}>
            <Row gutter={16}>
              <Col>
                <h1 className="h-mb">Data Availability</h1>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col className="card-bold-text">Last 5 Minutes:</Col>
              <Col className="card-sub-text">
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  `${connectivityStop?.data?.availability?.last5min}%`
                )}
              </Col>
            </Row>
            <Row gutter={12}>
              <Col className="card-bold-text">From Start To Stop:</Col>
              <Col className="card-sub-text">
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  `${connectivityStop?.data?.availability?.testDuration}%`
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <Row gutter={12} className="mt1">
              <Col className="card-bold-text">SOC:</Col>
              <Col className="card-sub-text">
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  `${connectivityStop?.data?.availability?.soc}%`
                )}
              </Col>
            </Row>
            <Row gutter={12} className="mt1">
              <Col className="card-bold-text">Data Lag:</Col>
              <Col className="card-sub-text">
                {testRunning ? (
                  <Spin size="small" />
                ) : (
                  `${connectivityStop?.data?.availability?.lag} Secs`
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Result;
