import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { Col, Row } from "antd";
import { getApis, postApis } from "../../../../common/apis";
import { useDispatch, useSelector } from "react-redux";
import VehicleDetails from "../../../VehicleDetails";
import {
  vehicleTestResultApiUrl,
  vehicleTestStartApiUrl,
  vehicleTestStopApiUrl,
} from "../../../../common/endpoints";
import Result from "./Result";
import History from "./History";
import Comment from "../../Comment";
import {
  setConnectivityStart,
  setConnectivityStop,
  setTestRunning,
} from "../../../../../reducers/eolSlice";

const Panels = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const connectivityStart = useSelector((state) => state.eol.connectivityStart);
  const connectivityStop = useSelector((state) => state.eol.connectivityStop);
  const testRunning = useSelector((state) => state.eol.testRunning);
  const selectedTab = useSelector((state) => state.eol.selectedTab);

  const resultApi = useCallback(async () => {
    const response = await getApis(vehicleTestResultApiUrl, {
      testId: vehicleDetails?.previousTests?.connectivity,
      imei: vehicleDetails?.imeiNumber,
    });
    dispatch(setTestRunning(response?.data?.status === "RUNNING"));
  }, [
    dispatch,
    vehicleDetails?.imeiNumber,
    vehicleDetails?.previousTests?.connectivity,
  ]);

  const startTest = async () => {
    setBtnDisable(true);
    const currentTime = Math.floor(Date.now());
    const response = await postApis(vehicleTestStartApiUrl, {
      imei: vehicleDetails?.imeiNumber,
      startTime: currentTime,
      type: "CONNECTIVITY",
    });
    dispatch(setConnectivityStart(response?.data));
    dispatch(setTestRunning(true));
    setBtnDisable(false);
  };

  useEffect(() => {
    if (
      vehicleDetails?.previousTests?.connectivity &&
      selectedTab === "connectivity"
    ) {
      resultApi();
    }
  }, [resultApi, selectedTab, vehicleDetails]);

  const stopTest = async () => {
    setBtnDisable(true);
    const currentTime = Math.floor(Date.now());
    const response = await postApis(vehicleTestStopApiUrl, {
      imei: vehicleDetails?.imeiNumber,
      stopTime: currentTime,
      testId:
        connectivityStart?.testId ||
        vehicleDetails?.previousTests?.connectivity,
      type: "CONNECTIVITY",
    });
    dispatch(setConnectivityStop(response?.data));
    dispatch(setTestRunning(false));
    setIsModalOpen(true);
    setBtnDisable(false);
  };

  const renderLayout = () => {
    if (connectivityStop?.status === "COMPLETED" || testRunning === true) {
      return <Result />;
    } else {
      return <History />;
    }
  };

  return (
    <Row className="mt1">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <VehicleDetails
              startTest={startTest}
              stopTest={stopTest}
              btnDisable={btnDisable}
            />
          </Col>
        </Row>
        <Comment
          isModalOpen={isModalOpen}
          testId={vehicleDetails?.previousTests?.connectivity}
          formName="connectivity"
        />
        {renderLayout()}
      </Col>
    </Row>
  );
};

export default Panels;
