import { createSlice } from "@reduxjs/toolkit";

const fieldOptions = [
  {
    label: "Analog Temperature",
    value: "ai_temp",
  },
  {
    label: "Analog Voltage",
    value: "ai_vin",
  },
  {
    label: "Location Speed",
    value: "location_speed",
  },
  {
    label: "Motor Current",
    value: "dc_current",
  },
  {
    label: "Motor Voltage",
    value: "dc_voltage",
  },
  {
    label: "MCS Temperature",
    value: "mcs_temp",
  },
  {
    label: "Motor Temperature",
    value: "motor_temp",
  },
  {
    label: "Motor Speed",
    value: "motor_speed",
  },
  {
    label: "Throttle",
    value: "throttle",
  },
];

const initialState = {
  days: 1,
  from: {},
  to: {},
  motorField: fieldOptions[0].value,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    reset: () => initialState,
    setDays: (state, action) => {
      return {
        ...state,
        days: action.payload,
      };
    },
    setFrom: (state, action) => {
      return {
        ...state,
        from: action.payload,
      };
    },
    setTo: (state, action) => {
      return {
        ...state,
        to: action.payload,
      };
    },
    setMotorField: (state, action) => {
      return {
        ...state,
        motorField: action.payload,
      };
    },
  },
});

export const selectFieldOptions = state => fieldOptions;
export const { setDays, reset, setFrom, setTo, setMotorField } =
  filterSlice.actions;
export default filterSlice.reducer;
