import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";

const Profile = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);

  return (
    <Card bordered={false} title="Profile">
      <Row>
        <Col>
          <Row gutter={16}>
            <Col className="bold">Email: </Col>
            <Col>{userDetails?.email}</Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Organisation: </Col>
            <Col>{userDetails?.organisations[0]?.name}</Col>
          </Row>
          <Row gutter={16} className="mt1">
            <Col className="bold">Role: </Col>
            <Col>{userDetails?.roles[0]}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default Profile;
