import { Select, Row, Col } from "antd";
import { useSelector } from "react-redux";

const Filters = ({ onChangeImei, imei }) => {
  const imeiList = useSelector((state) => state.eol.imeiList);

  // const onChangeDateRange = (dates, dateStrings) => {
  //   if (dates) {
  //     console.log("From: ", dates[0], ", to: ", dates[1]);
  //     console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
  //     const startdt = Date.parse(dateStrings[0]);
  //     const enddt = Date.parse(dateStrings[1]);

  //     console.log(startdt, enddt);
  //   } else {
  //     console.log("Clear");
  //   }
  // };
  return (
    <Row>
      <Col span={6}>
        {imeiList && imeiList.length > 0 && (
          <Select
            value={imei || imeiList[0]}
            style={{ width: 200 }}
            onChange={onChangeImei}
            options={imeiList.map((i) => ({
              label: i,
              value: i,
            }))}
          />
        )}
      </Col>
    </Row>
  );
};

export default Filters;
