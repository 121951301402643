import TextArea from "antd/es/input/TextArea";
import { Button, Col, Modal, Row, Space, Radio, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { vehicleAddCommentsApiUrl } from "../../../../common/endpoints";
import { postApis } from "../../../../common/apis";
import { toast } from "react-toastify";

const ReportIssue = ({
  isModalOpen,
  testId,
  targetSpeed,
}) => {
  const [issue, setIssue] = useState(1);
  const [open, setOpen] = useState(false);

  let initialValues = {
    key: 1,
    testId: testId,
    expectedValue: targetSpeed?.toString(),
    commentType: "ISSUE",
    value: null,
    comment: "",
  };

  useEffect(() => {
    if (isModalOpen) {
      setOpen(true);
    }
  }, [isModalOpen]);

  const onChangeIssue = (e) => {
    setIssue(e.target.value);
  };

  const onFinish = async (values) => {
    const selectRadio = radioData.filter((i) => i.value === values.key);
    const body = {
      ...initialValues,
      key: selectRadio[0].key,
      value: values.value,
      comment: values.comment,
    };

    await postApis(vehicleAddCommentsApiUrl, body)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Reported successfully!");
          setOpen(false);
        }
      })
      .catch((e) => {
        setOpen(true);
        toast.error(e.response?.data?.error || e.response?.data?.message);
      });
  };

  const radioData = [
    {
      key: "Top speed displayed in the dashboard more than the actual speed on the vehicle",
      value: 1,
    },
    {
      key: "Top speed displayed in the dashboard less than the actual speed on the vehicle observed",
      value: 2,
    },
    {
      key: "No speed data is seen on the dashboard",
      value: 3,
    },
    {
      key: "Other issue",
      value: 4,
    },
  ];

  const renderSpeedAndComment = () => {
    return (
      <Row gutter={16}>
        <Col span={6} offset={1}>
          <div className="text-center">Speed</div>
          <Form.Item
            key="speed"
            name="value"
            rules={[
              {
                required: true,
                message: "can't be blank",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={17}>
          <Form.Item
            key="comment"
            name="comment"
            rules={[
              {
                required: true,
                message: "can't be blank",
              },
            ]}
          >
            <TextArea placeholder="Comments" rows="3" />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderOnlyComment = () => {
    return (
      <Row gutter={16}>
        <Col span={19} offset={1}>
          <Form.Item
            key="comment"
            name="comment"
            rules={[
              {
                required: true,
                message: "Please enter your comments!",
              },
            ]}
          >
            <TextArea placeholder="Comments" rows="3" />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title="Report an Issue"
      open={open}
      onCancel={() => setOpen(false)}
      footer={[]}
    >
      <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <Form.Item name="key">
          <Radio.Group
            onChange={onChangeIssue}
            value={issue}
            className="mt2 mb2"
          >
            <Space direction="vertical">
              {radioData.map((radio) => (
                <React.Fragment key={radio.value}>
                  <Radio value={radio.value}>{radio.key}</Radio>
                  {issue === radio.value &&
                    (radio.value === 1 || radio.value === 2
                      ? renderSpeedAndComment()
                      : renderOnlyComment())}
                </React.Fragment>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="comment-btn right">
            Send
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportIssue;
