import { Card, Col, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import ModeLayout from "./ModeLayout";
import ReportIssue from "./ReportIssue";

const Result = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const response =
    Object.keys(eol?.dynoStop).length !== 0 ? eol?.dynoStop : eol?.dynoResult;
  const dynoStart = eol?.dynoStart;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const current_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&refresh=5s&panelId=4`;
  const eco_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&refresh=5s&panelId=15`;
  const reverse_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&refresh=5s&panelId=3`;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={16} className="mt1">
          <Col span={12}>
            {vehicleDetails?.imeiNumber && (
              <iframe
                title="Reverse"
                src={reverse_src}
                width="100%"
                height="250"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
          <Col span={12}>
            {vehicleDetails?.imeiNumber && (
              <iframe
                title="Current"
                src={current_src}
                width="100%"
                height="250"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
        </Row>
        <Row gutter={16} className="mt1">
          <Col span={12}>
            {vehicleDetails?.imeiNumber && (
              <iframe
                title="Speed"
                src={eco_src}
                width="100%"
                height="250"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
          <Col span={12}>
            <Card>
              {vehicleDetails?.driveModeSpeed?.map((item, index) => (
                <div key={index}>
                  {vehicleDetails?.imeiNumber && <ModeLayout item={item} />}
                </div>
              ))}
              <Row>
                <Col span={8} offset={18}>
                  <a href="#123" onClick={() => showModal()}>
                    Report an Issue
                  </a>
                </Col>
                <ReportIssue
                  isModalOpen={isModalOpen}
                  handleCloseModal={handleCloseModal}
                  targetSpeed={5}
                  testId={
                    vehicleDetails?.previousTests?.dyno || dynoStart?.testId
                  }
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Result;
