import TextArea from "antd/es/input/TextArea";
import { Button, Modal, Checkbox, Form } from "antd";
import { vehicleAddCommentsApiUrl } from "../../common/endpoints";
import { postApis } from "../../common/apis";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const Comment = ({ isModalOpen, testId, formName }) => {
  const [open, setOpen] = useState(false);

  let initialValues = {
    key: "Is the vehicle in good condition without any issues so far?",
    testId: testId,
    commentType: "TEXT",
    value: [],
    comment: "",
  };

  useEffect(() => {
    if (isModalOpen) {
      setOpen(true);
    }
  }, [isModalOpen]);

  const onFinish = async (values) => {
    const body = {
      ...initialValues,
      value: values.value[0],
      comment: values.comment,
    };
    await postApis(vehicleAddCommentsApiUrl, body)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Commented successfully!");
          setOpen(false);
        }
      })
      .catch((e) => {
        setOpen(true);
        toast.error(e.response?.data?.message);
      });
  };

  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const validateCheckboxGroup = (_, value) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Select at least one"));
    }
    return Promise.resolve();
  };

  return (
    <Modal title="Vehicle Status" open={open} closable={false} footer={[]}>
      <Form layout="vertical" onFinish={onFinish} name={formName}>
        Is the vehicle in good condition without any issues so far?
        <Form.Item
          key="checkbox"
          name="value"
          initialValue={["yes"]}
          rules={[{ validator: validateCheckboxGroup }]}
        >
          <Checkbox.Group
            options={options.map((option) => ({
              ...option,
              key: option.value.toString(),
            }))}
            className="mt1 mb1"
          />
        </Form.Item>
        <Form.Item
          key="comment"
          name="comment"
          rules={[
            {
              required: true,
              message: "Can't be blank",
            },
          ]}
        >
          <TextArea placeholder="Comments" rows="3" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="comment-btn right">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Comment;
