import { Button, Card, Col, Row } from "antd";
import Timer from "../common/Timer";
import SCOOTER from "../../assets/images/scooter3.jpeg";
import { useSelector } from "react-redux";

const VehicleDetails = ({ startTest, stopTest, btnDisable }) => {
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const testRunning = eol?.testRunning;

  return (
    <Card bordered={false}>
      <Row>
        <Col span={5}>
          <img
            alt="Brand"
            src={
              vehicleDetails?.images?.length > 0
                ? vehicleDetails?.images[0]
                : SCOOTER
            }
            width="170px"
            height="100px"
          />
        </Col>
        <Col span={8} className="mt1">
          <Row gutter={16}>
            <Col className="card-bold-text">Vehicle No:</Col>
            <Col className="card-sub-text">{vehicleDetails?.imeiNumber}</Col>
          </Row>
          <Row gutter={16}>
            <Col className="card-bold-text">Model:</Col>
            <Col className="card-sub-text">
              {vehicleDetails?.vehicleModelName}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="card-bold-text">Birth of Vehicle:</Col>
            <Col className="card-sub-text">
              {new Date(vehicleDetails?.birthOfVehicle).toLocaleString()}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="mt1">
          <Row gutter={16}>
            <Col className="card-bold-text">Manufacture Date:</Col>
            <Col className="card-sub-text">{vehicleDetails?.mfrDate}</Col>
          </Row>
          <Row gutter={16}>
            <Col className="card-bold-text">Color:</Col>
            <Col className="card-sub-text">{vehicleDetails?.color}</Col>
          </Row>
        </Col>
        <Col span={3} offset={2} className="text-center">
          {testRunning ? (
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  danger
                  disabled={btnDisable}
                  onClick={stopTest}
                >
                  Stop
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  className="start"
                  onClick={startTest}
                  disabled={btnDisable}
                >
                  Start
                </Button>
              </Col>
            </Row>
          )}
          <Row className="mt1">
            <Col span={24}>
              <Timer className={"timer"} loading={testRunning} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default VehicleDetails;
