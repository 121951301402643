import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

const Panels = (props) => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const motorField = useSelector((state) => state.filter.motorField);

  const { fleet, imei } = props;
  const src1 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163c/controller-dashboard?orgId=${userDetails?.organisations[0]?.id}&from=1685956212000&to=1690708212000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=2`;
  const src2 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163c/controller-dashboard?orgId=${userDetails?.organisations[0]?.id}&from=1687201091000&to=1690670017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=6`;
  const src3 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163c/controller-dashboard?orgId=${userDetails?.organisations[0]?.id}&from=1687201091000&to=1690670017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=3`;
  const src4 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163c/controller-dashboard?orgId=${userDetails?.organisations[0]?.id}&from=1687201091000&to=1690670017000&var-fleet=${fleet}&var-imei=${imei}&var-bucket_interval=1h&var-motorfield=${motorField}&panelId=7`;

  return (
    <Row className="mt2">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {fleet && imei && (
                  <iframe
                    title="Generic Trend"
                    src={src1}
                    width="100%"
                    height="260"
                    frameBorder="0"
                  ></iframe>
                )}
              </Col>
            </Row>
            <Row className="mt1">
              <Col span={24}>
                {fleet && imei && (
                  <iframe
                    title="Histogram"
                    src={src3}
                    width="100%"
                    height="260"
                    frameBorder="0"
                  ></iframe>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {fleet && imei && (
              <iframe
                title="Box Plot"
                src={src2}
                width="100%"
                height="540"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {fleet && imei && (
              <iframe
                title="CandleStick"
                src={src4}
                width="100%"
                height="260"
                frameBorder="0"
              ></iframe>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Panels;
