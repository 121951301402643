import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import ReportIssue from "./ReportIssue";
import { useState } from "react";

const ModeLayout = ({ item, startTime }) => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const dynoStart = eol?.dynoStart;
  const response =
    Object.keys(eol?.dynoStop).length !== 0 ? eol?.dynoStop : eol?.dynoResult;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const eco_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-mode=${item?.mode}&from=${response.startTime}&to=${response.stopTime}&refresh=5s&panelId=15`;
  const reverse_src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&refresh=5s&panelId=3`;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const returnSpeedWithIcon = (modeValue, bool) => {
    if (modeValue || modeValue === 0) {
      return {
        speed: modeValue,
        checked: bool,
      };
    }
  };
  const modeResult = () => {
    if (item.mode === "ECO") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.eco,
        response?.data?.threshold?.eco_test_passed
      );
    } else if (item.mode === "CITY") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.city,
        response?.data?.threshold?.city_test_passed
      );
    } else if (item.mode === "POWER") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.power,
        response?.data?.threshold?.power_test_passed
      );
    } else if (item.mode === "REVERSE") {
      return returnSpeedWithIcon(
        response?.data?.maxSpeed?.reverse,
        response?.data?.threshold?.reverse_test_passed
      );
    }
  };

  const connectionIcon = (status) => {
    if (status === true) {
      return (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "18px", margin: "0rem 0.5rem" }}
        />
      );
    } else if (status === false) {
      return (
        <CloseCircleTwoTone
          twoToneColor="#f81d22"
          style={{ fontSize: "18px", margin: "0rem 0.5rem" }}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <Row gutter={16} className="mt1">
      <Col span={24}>
        <Row justify="space-between">
          <Col span={12} className="mode-header">
            {item.mode} - Target: {item.max} {item.unit}
          </Col>
          <Col span={12}>
            Recorded: <b>{modeResult()?.speed}</b> {item.unit}
            {connectionIcon(modeResult()?.checked)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ModeLayout;
