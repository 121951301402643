import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const userDetails = useSelector((state) => state.auth.userDetails);
  const role = userDetails.roles[0];

  useEffect(() => {
    setTimeout(function () {
      if (role === "Test Engineer") {
        navigate("/eolTestHeartBeat");
      } else {
        navigate("/motorStatus");
      }
    }, 2000);
  }, [navigate, role]);

  return (
    <iframe
      title="Login"
      src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/login?token=${authToken}`}
      width="0"
      height="0"
      frameBorder="0"
    ></iframe>
  );
};

export default Dashboard;
