import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const EolSummary = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={8} className="mt1">
          <Col span={12}>
            <iframe
              title="Test History"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691212982571&to=1691234582571&panelId=7`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={12}>
            <iframe
              title="Test History"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&&from=1691546277854&to=1691567877854&panelId=2`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
        </Row>
        <Row gutter={8} className="mt1">
          <Col span={24}>
            <iframe
              title="Test History"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&&from=1691403867124&to=1691425467125&panelId=1`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
        </Row>
        <Row gutter={8} className="mt1">
          <Col span={4}>
            <iframe
              title="TCU"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404090116&to=1691425690116&panelId=5`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={4}>
            <iframe
              title="Battery"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404143515&to=1691425743515&panelId=8`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={4}>
            <iframe
              title="Analog"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404185332&to=1691425785333&panelId=9`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={4}>
            <iframe
              title="Accelerometer"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404224581&to=1691425824582&panelId=10`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={4}>
            <iframe
              title="Motor"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404300239&to=1691425900240&panelId=11`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
          <Col span={4}>
            <iframe
              title="Location"
              src={`${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/e24b93f7-cc13-40f8-9a83-0106979f23e3/eol-summary?orgId=${userDetails?.organisations[0]?.id}&from=1691404331876&to=1691425931877&panelId=12`}
              width="100%"
              height="300"
              frameBorder="0"
            ></iframe>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EolSummary;
