import React, { useState } from "react";
import { Select, Space, DatePicker, Row, Col } from "antd";
import "./styles.css";
import Panels from "../Panels";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

// const fleetOptions = ["DUNZO", "NDS-TEST"];
// const vehicleOptions = {
//   DUNZO: ["861100065560043", "All"],
//   "NDS-TEST": ["861100065608966", "All"],
// };

const Queries = () => {
  const userDetails = useSelector((state) => state.auth);

  const fleetOptions = JSON.parse(userDetails.fleetOptions);
  const vehicleOptions = JSON.parse(userDetails.vehicleOptions);

  const [fleet, setFleet] = useState(fleetOptions[0]);
  const [imei, setImei] = useState(vehicleOptions[fleetOptions[0]][0]);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [endTimestamp, setEndTimestamp] = useState(0);

  const onChangeFleet = (value) => {
    setFleet(value);
    setImei(vehicleOptions[value][0]);
  };

  const onChangeImei = (value) => {
    setImei(value);
  };

  const onChangeDateRange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      const startdt = Date.parse(dateStrings[0]);
      const enddt = Date.parse(dateStrings[1]);

      console.log(startdt, enddt);
    } else {
      console.log("Clear");
    }
  };

  const imeiFilterOprions = vehicleOptions[fleet];
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={5}>
            <Select
              defaultValue={fleetOptions[0]}
              style={{ width: 200 }}
              onChange={onChangeFleet}
              options={fleetOptions.map((f) => ({ label: f, value: f }))}
            />
          </Col>
          <Col span={5}>
            <Select
              value={imei}
              style={{ width: 200 }}
              onChange={onChangeImei}
              options={imeiFilterOprions.map((i) => ({
                label: i,
                value: i,
              }))}
            />
          </Col>

          <Col span={6} offset={8}>
            <Space direction="horizontal">
              <RangePicker allowEmpty={false} onChange={onChangeDateRange} />
            </Space>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Panels
              fleet={fleet}
              imei={imei}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Queries;
