import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import ConnectivityTests from "./ConnectivityTests";

const History = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  // const src1 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2df/eol-connectivity?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&panelId=3`;
  const src2 = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2df/eol-connectivity?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&panelId=4`;

  return (
    <Row gutter={16} className="mt1">
      <Col span={16}>
        <ConnectivityTests />
        {/* {vehicleDetails?.imeiNumber && (
          <iframe
            title="History"
            src={src1}
            width="100%"
            height="282"
            frameBorder="0"
          ></iframe>
        )} */}
      </Col>
      <Col span={8}>
        {vehicleDetails?.imeiNumber && (
          <iframe
            title="Count"
            src={src2}
            width="100%"
            height="300"
            frameBorder="0"
          ></iframe>
        )}
      </Col>
    </Row>
  );
};
export default History;
