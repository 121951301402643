import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import Panels from "./Panels";
import { getApis } from "../../../common/apis";
import {
  vehicleDetailsApiUrl,
  imeiListApiUrl,
} from "../../../common/endpoints";
import { useDispatch } from "react-redux";
import {
  setVehicleDetails,
  setImeiList,
  setDynoStop,
  setDynoResult,
} from "../../../../reducers/eolSlice";
import Filters from "./Filters";
import { useNavigate } from "react-router-dom";

const EolTestDyno = () => {
  const dispatch = useDispatch();
  const [imei, setImei] = useState("");
  const navigate = useNavigate();

  const getImeiList = useCallback(async () => {
    const response = await getApis(imeiListApiUrl);
    dispatch(setImeiList(response?.data));
    setImei(response?.data[0]);
  }, [dispatch]);

  const getVehicleDetails = useCallback(async () => {
    const response = await getApis(vehicleDetailsApiUrl, {
      imei: imei,
    });
    dispatch(setVehicleDetails(response?.data));
  }, [dispatch, imei]);

  useEffect(() => {
    getImeiList();
  }, [getImeiList]);

  useEffect(() => {
    if (imei !== "") {
      getVehicleDetails();
    }
  }, [getVehicleDetails, imei]);

  const onChangeImei = (value) => {
    setImei(value);
    dispatch(setVehicleDetails({}));
    dispatch(setDynoResult({}));
    dispatch(setDynoStop({}));
  };

  const onChange = (key) => {
    navigate(`/${key}`);
  };

  const items = [
    {
      key: "eolTestHeartBeat",
      label: `Heart Beat`,
    },
    {
      key: "eolTestDyno",
      label: `Dyno`,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Tabs
          tabBarExtraContent={<a href="/eolTestDyno">Go Back</a>}
          defaultActiveKey={"eolTestDyno"}
          items={items}
          onChange={onChange}
        />
        <Filters onChangeImei={onChangeImei} imei={imei} />
        <Panels />
      </Col>
    </Row>
  );
};

export default EolTestDyno;
