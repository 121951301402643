import { NicheJwtLogin } from "niche-ui-component";
import { userJwtLoginSchemaUrl, userJwtLoginUrl } from "../common/endpoints";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../reducers/authSlice";
import LOGO from "../../assets/images/logo.png";
import "./style.css";
import jwt from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (response) => {
    localStorage.setItem("authToken", response?.data?.authToken);
    const decodedToken = jwt(response?.data?.authToken);
    const role = decodedToken.roles[0];

    let fleetOptions = [];
    let vehicleOptions = {};

    if (role === "Fleet Manager") {
      fleetOptions = ["LIVE-FLEET"];
      vehicleOptions = {
        "LIVE-FLEET": ["861100065560043", "861100065560266", "All"],
      };
    } else if (role === "Test Engineer") {
      fleetOptions = ["ZOMATO"];
      vehicleOptions = {
        ZOMATO: ["861100065608966", "All"],
      };
    } else {
      fleetOptions = ["LIVE-FLEET", "ZOMATO"];
      vehicleOptions = {
        "LIVE-FLEET": ["861100065560043", "861100065560266", "All"],
        ZOMATO: ["861100065608966", "All"],
      };
    }
    localStorage.setItem("fleetOptions", JSON.stringify(fleetOptions));
    localStorage.setItem("vehicleOptions", JSON.stringify(vehicleOptions));
    dispatch(setLoggedIn());
    navigate("/");
  };

  return (
    <Row>
      <Col span={12}>
        <Row>
          <Col className="banner"></Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row className="mt4">
          <Col span={10} offset={4}>
            <img src={LOGO} alt="Logo" style={{ width: "100px" }} />
          </Col>
        </Row>
        <Row className="mt3">
          <Col span={16} offset={4}>
            <Row>
              <Col className="h1-header">Login to your account</Col>
            </Row>
            <Row>
              <Col className="sub-header mt-space">Welcome back!</Col>
            </Row>
            <Row className="mt2">
              <Col>
                <NicheJwtLogin
                  handleSubmit={handleSubmit}
                  url={userJwtLoginUrl}
                  schemaUrl={userJwtLoginSchemaUrl}
                  linkText="Forgot Password?"
                  linkUrl="/forgotPassword"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
