import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import DynoTests from "./DynoTests";

const History = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);

  const src = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4064/eol-dyno-testing?orgId=${userDetails?.organisations[0]?.id}&var-imei=${vehicleDetails?.imeiNumber}&panelId=16`;

  return (
    <Row gutter={16} className="mt1">
      <Col span={16}>
        <DynoTests />
      </Col>
      <Col span={8}>
        {vehicleDetails?.imeiNumber && (
          <iframe
            title="Count"
            src={src}
            width="100%"
            height="300"
            frameBorder="0"
          ></iframe>
        )}
      </Col>
    </Row>
  );
};
export default History;
