import { createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";

const setUserDetails = () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    const decodedToken = jwt(token);
    return decodedToken;
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: localStorage.getItem("authToken") !== null,
    email: localStorage.getItem("emailAddress") || "",
    fleetOptions: localStorage.getItem("fleetOptions") || [],
    vehicleOptions: localStorage.getItem("vehicleOptions") || {},
    userDetails: setUserDetails(),
  },
  reducers: {
    setLoggedIn: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        email: localStorage.getItem("emailAddress"),
        fleetOptions: localStorage.getItem("fleetOptions"),
        vehicleOptions: localStorage.getItem("vehicleOptions"),
        userDetails: setUserDetails(),
      };
    },
    setLoggedOut: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        email: "",
        fleetOptions: [],
        vehicleOptions: {},
      };
    },
  },
});

export const { setLoggedIn, setLoggedOut } = authSlice.actions;
export default authSlice.reducer;
