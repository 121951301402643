import React, { useEffect, useState } from "react";
import { Space, Table, Button } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  vehicleTestListUrl,
  vehicleTestResultApiUrl,
} from "../../../../common/endpoints";
import { getApis } from "../../../../common/apis";
import { setConnectivityStop } from "../../../../../reducers/eolSlice";
import { useDispatch, useSelector } from "react-redux";

const ConnectivityTests = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const vehicleDetails = useSelector((state) => state?.eol?.vehicleDetails);

  const getData = async () => {
    await getApis(vehicleTestListUrl, {
      imei: vehicleDetails?.imeiNumber,
      type: "CONNECTIVITY",
    })
      .then((res) => setData(res?.data))
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      title: "Test Date",
      dataIndex: "testDate",
      key: "testDate",
      render: (testDate) => new Date(testDate).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Test#",
      dataIndex: "iteration",
      key: "iteration",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            className="play"
            icon={<CaretRightOutlined />}
            onClick={() => handleButtonClick(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (vehicleDetails?.imeiNumber) {
      getData();
    }
  }, [vehicleDetails]);

  const handleButtonClick = async (record) => {
    const response = await getApis(vehicleTestResultApiUrl, {
      testId: record?.testId,
      imei: record?.imei,
    });
    dispatch(setConnectivityStop(response?.data));
  };

  return (
    <div className="no-padding-w-box">
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.testId}
        pagination={data.length > 3 ? { pageSize: 3 } : false}
      />
    </div>
  );
};
export default ConnectivityTests;
