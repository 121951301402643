import axios from "axios";

export const getApis = async (url, params) => {
  try {
    return await axios({
      method: "get",
      url: url,
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    throw e;
  }
};

export const postApis = async (url, body) => {
  try {
    return await axios({
      method: "post",
      url: url,
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    });
  } catch (e) {
    throw e;
  }
};
