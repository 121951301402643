import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Notification from "./components/common/notification";
import Routing from "./Routing";

const App = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    document.title = userDetails?.organisations[0].name || "Niche EV";
  }, [userDetails?.organisations]);

  return (
    <BrowserRouter>
      <Notification />
      <Routing />
    </BrowserRouter>
  );
};
export default App;
