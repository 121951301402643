import { Route, Routes, Navigate } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import Login from "./components/Login";
import { useSelector } from "react-redux";
import MotorStatus from "./components/Dashboard/MotorStatus";
import DriverBehaviour from "./components/Dashboard/DriverBehaviour";
import Dashboard from "./components/Dashboard";
import EolSummary from "./components/Dashboard/EolSummary";
import Profile from "./components/Profile";
import EolTestDyno from "./components/Dashboard/EolTest/Dyno";
import EolTestConnectivity from "./components/Dashboard/EolTest/Connectivity";

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const renderIfLoggedIn = (component) => {
    return isLoggedIn ? (
      <AuthLayout>{component}</AuthLayout>
    ) : (
      <Navigate to="/login" replace={true} />
    );
  };

  const renderIfNotLoggedIn = (component) => {
    return !isLoggedIn ? component : <Navigate to="/" replace={true} />;
  };

  return (
    <Routes>
      <Route path="/" exact element={renderIfLoggedIn(<Dashboard />)} />
      <Route path="/profile" exact element={renderIfLoggedIn(<Profile />)} />
      <Route path="/login" exact element={renderIfNotLoggedIn(<Login />)} />
      <Route
        path="/motorStatus"
        exact
        element={renderIfLoggedIn(<MotorStatus />)}
      />
      <Route
        path="/driverBehaviour"
        exact
        element={renderIfLoggedIn(<DriverBehaviour />)}
      />
      <Route
        path="/eolTestHeartBeat"
        exact
        element={renderIfLoggedIn(<EolTestConnectivity />)}
      />
      <Route
        path="/eolTestDyno"
        exact
        element={renderIfLoggedIn(<EolTestDyno />)}
      />
      <Route
        path="/eolSummary"
        exact
        element={renderIfLoggedIn(<EolSummary />)}
      />
    </Routes>
  );
};

export default Routing;
